import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HomeLayout from "../../components/HomeLayout"
import SEO from "../../components/seo"
import TopNav from "../../components/TopNav/TopNav"
import HeaderAlternate from "../../components/HeaderAlternate"
import { Container, useTheme } from "@material-ui/core"
import SectionContainer from "../../components/SectionContainer"
import ContactUs from "../../components/ContactUs/ContactUs"
import Footer from "../../components/Footer/Footer"
import ResourceList from '../../components/ResourceList';

const setMenuItems = items => {
  return items.map(({ menuItemTitle, menuItemSlug }, index) => ({
    id: index,
    title: menuItemTitle,
    path: menuItemSlug,
    activePath: menuItemSlug,
    exact: true,
  }))
}

const EarlyAccessProgram = () => {
  const data = useStaticQuery(graphql`
    query earlyAccessProgramPageQuery {
      contentfulGsConfluenceEarlyAccessProgramPage(homePageTitle: { eq: "Main Early Access Program Page" }) {
        associatedMenu {
          menuItems {
            menuItemTitle
            menuItemSlug
          }
        }
        headerTitle
        headerDescription {
          headerDescription
        }
        perks {          
          title          
        }
        resourcesTitle
        resourcesDescription {
          resourcesDescription
        }
        resources {
          title
          file {
            file {
              url
            }
          }
        }       
      }
    }
  `)

  const theme = useTheme()

  const pageData = data.contentfulGsConfluenceEarlyAccessProgramPage
  const {
    headerTitle,
    headerDescription,
    perks,
    resourcesTitle,
    resourcesDescription,
    resources,
    associatedMenu,
  } = pageData

  return (
    <HomeLayout>
      <SEO title="Home" />
      <TopNav menuItems={setMenuItems(associatedMenu.menuItems)} />
      <SectionContainer maxWidth="lg">
        <HeaderAlternate
          title={headerTitle}
          description={headerDescription.headerDescription}
          perks={perks}
        />
      </SectionContainer>
      <SectionContainer bgcolor="#1C2541">
        <Container maxWidth="lg">
          <ResourceList
            title={resourcesTitle}
            description={resourcesDescription.resourcesDescription}
            resources={resources}
          />
        </Container>
      </SectionContainer>
      <SectionContainer maxWidth="sm">
        <ContactUs />
      </SectionContainer>
      <SectionContainer bgcolor="#f1f1f1">
        <Container maxWidth="md">
          <Footer menuItems={setMenuItems(associatedMenu.menuItems)} />
        </Container>
      </SectionContainer>
    </HomeLayout>
  )
}

export default EarlyAccessProgram
