import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@material-ui/core';
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"
import RequestEarlyAccess from "../RequestEarlyAccess/RequestEarlyAccess"
import CheckIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  lreLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: theme.palette.text.primary
  },
  secondaryDescription: {
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 400,
    color: props => props.color,
    textAlign: props => props.align,
    marginTop: theme.spacing(8)
  },
  headerText: {
    textAlign: 'center'
  },
  perkList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(6),
    "& li": {
      display: 'inline-block',
      margin: theme.spacing(0, 3)
    }
  },
  perkIcon: {
    color: theme.palette.primary.main,
    minWidth: 40,
    verticalAlign: 'text-bottom'
  },
  perkText: {
    display: 'inline'
  }
}))

const HeaderAlternate = ({ title, description, perks }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Grid container spacing={mobile ? 4 : 8} className={classes.root}>
      <Grid item xs={12} sm={12} className={classes.headerText}>
        <SectionTitle align="center">{title}</SectionTitle>
        <SectionText align="center">{description}</SectionText>
        <ul className={classes.perkList}>
          {perks.map(perk => (
            <li className={classes.perkItem}>
              <CheckIcon className={classes.perkIcon} />
              <Typography variant="h6" className={classes.perkText}>
                {perk.title}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  )
}

HeaderAlternate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}

export default HeaderAlternate
